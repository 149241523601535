<script setup lang="ts">
import { formatPhoneAndMask } from '@artmizu/utils'
import { useAddressStore } from '~/store/address'
import { useSocialsStore } from '~/store/socials'
import { useChooseAddressStore } from '~/store/modal/chooseAddress'
import { version } from '~/package.json'
import { useResizeStore } from '~/store/resize'
import { useSearchMobileStore } from '~/store/modal/searchMobile'
import { useChooseProject } from '~/store/modal/chooseProject'
import SearchBar from '~/components/SearchBar/SearchBar.vue'
import { useSupportModalStore } from '~/store/modal/support'

const { $emitter } = useNuxtApp()
const searchBar = shallowRef<InstanceType<typeof SearchBar>>()
const router = useRouter()
const route = useRoute()
const addressStore = useAddressStore()
const resizeStore = useResizeStore()
const chooseProjectStore = useChooseProject()
const socials = useSocialsStore()
const supportModalStore = useSupportModalStore()

const { enable: enableMap } = useChooseAddressStore()
const { enable: enableSearchModal } = useSearchMobileStore()
const isMobile = computed(() => resizeStore?.isMobile)
const isCartPage = computed(() => route.path.includes('cart'))

function showDKMap() {
  $emitter.emit('choose-city-modal-enable', 'dkChooseCityModalEnable')
  enableMap()
}

function onSearchBarClick() {
  if (isMobile.value) {
    searchBar.value?.blurInput()
    enableSearchModal()
  }
}

function searchPush(path: string) {
  router.push(`/search/?q=${path}`)
}

function stopSearch() {
  if (route.path.includes('search'))
    router.push('/')
}
</script>

<template>
  <header class="header">
    <div class="header__main-menu">
      <div class="header__logo-wrapper">
        <nuxt-link
          class="header__logo"
          to="/"
          :title="`Близко версии: ${version}`"
        >
          <img
            src="./asset/logo-desktop.svg"
            alt="05.ру Близко доставка продуктов"
            class="header__logo-img header__logo-img_desktop"
          >
          <img
            src="./asset/logo.svg"
            alt="05.ру Близко доставка продуктов"
            class="header__logo-img header__logo-img_mobile"
          >
        </nuxt-link>
        <div
          class="header__button"
          @click="chooseProjectStore.enable"
        />
      </div>

      <div
        class="header__delivery-address"
        :class="{ 'header__delivery-address_exist': addressStore.isCurrentExist }"
        @click="showDKMap"
      >
        <img
          src="./asset/map.svg"
          alt="Установить адрес доставки"
          class="header__delivery-address-img"
        >
        <div class="header__delivery-address-text" :class="{ 'header__delivery-address-text_empty': !addressStore.isCurrentExist }">
          <span v-if="addressStore.isCurrentExist" class="header__delivery-address-title">Адрес <span class="header__delivery-address-title-delivery">доставки</span></span>
          <div class="header__delivery-address-city-wrap">
            <p class="header__delivery-address-city">
              <span class="header__delivery-address-city-name header__delivery-address-city-name_desktop">{{ addressStore.formatted || 'Укажите адрес доставки' }}</span>
              <span class="header__delivery-address-name header__delivery-address-city-name_mobile">{{ addressStore.formatted || 'Укажите адрес' }}</span>
            </p>
          </div>
        </div>
      </div>
      <div
        class="header__search"
        :class="{ header__search_cart: isCartPage }"
        @click="onSearchBarClick"
      >
        <SearchBar
          ref="searchBar"
          @search="searchPush($event)"
          @stop-search="stopSearch"
        />
      </div>
      <div class="header__right-block">
        <div class="header__phone">
          <div
            v-if="socials.phone"
            class="header__phone-top"
          >
            <a
              :href="`tel:${formatPhoneAndMask(socials.phone)}`"
              class="h5 link link_wu header__phone-link"
            >
              {{ socials.phone }}
            </a>
          </div>
          <div class="header__phone-bottom">
            <a
              v-if="socials.telegram"
              :href="socials.telegram"
              class="link-icon link-icon_blue link-icon_wu header__phone_social"
            >
              <div class="icon-social-telegram header__phone-icon" />
              <div class="link-icon__text header__phone-social">Телеграм</div>
            </a>
            <a
              v-if="socials.whatsApp"
              :href="socials.whatsApp"
              class="link-icon link-icon_green link-icon_wu header__phone_social"
            >
              <div class="icon-social-whatsapp header__phone-icon" />
              <div class="link-icon__text header__phone-social">WhatsApp</div>
            </a>
          </div>
        </div>
        <ViewportToogle>
          <template #desktop>
            <div class="header__profile">
              <UserMenu />
            </div>
          </template>
          <template #mobile>
            <div class="header__support" @click="supportModalStore.enable" />
          </template>
        </ViewportToogle>
      </div>
    </div>
  </header>
</template>

<style lang="postcss" scoped>
.header {
  background-color: #fff;

  &__right-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 358px;
  }

  &__main-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 22px;
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: flex;
    align-items: center;
    height: 43px;
  }

  &__logo-img {
    max-width: 100%;
    max-height: 100%;
    margin-right: 12px;

    &_mobile {
      display: none;
    }
  }

  &__logo-name {
    margin-top: 7px;
  }

  &__button {
    width: 22px;
    height: 22px;
    cursor: pointer;
    background-color: #fff;
    background-image: svg-load('./asset/arrow-bottom.svg');
    background-repeat: no-repeat;
    background-position: 50% 60%;
    background-size: 10px 6px;
    border: 1px solid #efefef;
    border-radius: 50%;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  }

  &__delivery-address-title {
    font-size: 12px;
    line-height: 14px;
    color: #818181;
  }

  &__delivery-address-city-wrap {
    width: 175px;
  }

  &__delivery-address-city-name_mobile {
    display: none;
  }

  &__delivery-address-city {
    overflow: hidden;
    line-height: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__delivery-address {
    display: flex;
    align-items: center;
    padding: 6px;
    padding-right: 15px;
    margin: 0 8px 0 32px;
    cursor: pointer;
    background: svg-load('./asset/arrow-right.svg', fill=#000) no-repeat bottom 16px right 9px;
    background-size: 5px 9px;
    border: 1px solid #EAEAEA;
    border-radius: 5px;

    &-img {
      margin-right: 3px;
    }
  }

  &__phone-top {
    padding-bottom: 2px;
  }

  &__search {
    flex: 2;
    max-width: 636px;
    margin-right: 28px;
  }

  &__phone {
    display: flex;
    flex-direction: column;
    padding: 4px 15px 0 0;
    margin-right: auto;
    text-align: left;

    &_social {
      margin-right: 8px;
      font-size: 12px;
      line-height: 17px;
    }

    &-icon {
      margin-right: 3px;
      vertical-align: top;
      background-size: contain;
    }
  }

  @media (max-width: 1600px) {

    &__delivery-address {
      margin: 0 8px 0 45px;
    }
  }

  @media (max-width: 1300px) {

    &__delivery-address {
      margin: 0 8px 0 8px;
    }

    &__right-block {
      width: 298px;
    }
  }

  @media (max-width: 1250px) {

    &__logo {
      margin-right: 7px;
    }

    &__delivery-address {
      padding-right: 15px;
      margin: 0 10px;
      background-position: bottom 17px right 6px;
    }

    &__delivery-address-city-wrap {
      width: 145px;
    }

    &__phone-link {
      font-size: 16px;
    }
  }

  @media (max-width: 1150px) {

    &__logo-img {
      margin-right: 0;

      &_desktop {
        display: none;
      }

      &_mobile {
        display: block;
        width: 40px;
        height: 40px;
      }
    }
  }

  @media (max-width: 1100px) {

    &__phone {
      padding-right: 0;
    }

    &__right-block {
      width: 248px;
    }
  }

  @media (max-width: 1023px) {

    &__delivery-address-city-wrap {
      width: 160px;
    }

    &__right-block {
      width: fit-content;
    }

    &__phone {
      display: none;
    }
  }

  @media (max-width: 800px) {

    &__support {
      width: 100%;
      padding: 14px;
      background-image: url(./asset/support.svg);
    }

    &__delivery-address-city-name {

      &_mobile {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &_desktop {
        display: none
      }
    }

    &__delivery-address-title-delivery {
      display: none;
    }

    &__main-menu {
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 12px 15px 0;
    }

    &__delivery-address-city-wrap {
      width: 100%;
    }

    &__delivery-address-city {
      font-weight: 500;
    }

    &__delivery-address-text {
      width: 48vw;

      &_empty {
        width: initial;
      }
    }

    &__delivery-address {
      padding: 6px 20px 6px 2px;
      background-position: bottom 9px right 6px;
      background-size: 6px 9px;
      border: none;

      &_exist {
        padding: 6px;
        background: none;
      }

      &-img {
        display: none;
      }
    }

    &__logo {
      width: 40px;
      height: 40px;
      margin-right: 8px;
    }

    &__logo-name,
    &__phone {
      display: none;
    }

    &__search {
      flex: initial;
      flex-grow: 1;
      order: 2;
      width: calc(100vw - 108px);
      max-width: 100%;
      margin-top: 12px;
      margin-right: 0;

      &_cart {
        display: none;
      }
    }
  }
}
</style>
