<script lang="ts" setup>
import { formatPhoneAndMask } from '@artmizu/utils'
import { useSocialsStore } from '~/store/socials'
import { version } from '~/package.json'

const social = useSocialsStore()
const year = new Date().getFullYear()
</script>

<template>
  <footer class="footer">
    <div class="footer__content">
      <nav class="footer__content-right">
        <div class="footer__col footer__col_large">
          <div
            v-if="social.appStoreUrl"
            class="footer__store footer__store_apple"
          >
            <a
              :href="social.appStoreUrl"
              class="link link_wu"
            > App Store </a>
          </div>
          <div
            v-if="social.googlePlay"
            class="footer__store footer__store_google"
          >
            <a
              :href="social.googlePlay"
              class="link link_wu"
            > Google Play </a>
          </div>
        </div>
        <div class="footer__col footer__col_large">
          <div
            v-if="social.email"
            class="footer__col-email"
          >
            <div class="footer__link-face">
              Физ. лицам:
            </div>
            <a
              :href="`mailto:${social.email}`"
              class="footer__link link link_wu"
            >{{ social.email }}</a>
          </div>
          <div
            v-if="social.phone"
            class="footer__col-phone"
          >
            <a
              :href="`tel:${formatPhoneAndMask(social.phone)}`"
              class="footer__phone-link link link_wu"
            >
              {{ social.phone }}
            </a>
          </div>
        </div>
      </nav>
    </div>
    <div class="footer__bottom">
      <div class="footer__bottom-left grey-text">
        {{ year }} © 05.ru Цена на сайте носит информационный характер и не является публичной офертой. &nbsp;
        <nuxt-link
          to="/about/terms-of-use/"
          class="link link_grey"
        >
          Пользовательское соглашение
        </nuxt-link>, публичная оферта. Версия
        {{ version }}
      </div>
    </div>
  </footer>
</template>

<style lang="postcss" scoped>
.footer {
  display: none;
  padding: 21px 30px 27px;
  font-size: 13px;
  line-height: 18px;
  background-color: #fff;
  border-top: 1px solid #f2f2f2;

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__content-right {
    display: flex;
    width: 50%;
  }

  &__menu-link {
    padding-right: 9px;
    padding-bottom: 8px;

    &_title {
      padding-bottom: 10px;
    }
  }

  &__col {
    width: 33.33%;
    font-size: 13px;

    &_large {
      font-size: 18px;
      font-weight: 500;
      line-height: 32px;
    }
  }

  &__store {

    &:before {
      display: inline-block;
      width: 24px;
      height: 22px;
      margin-right: 9px;
      vertical-align: middle;
      content: '';
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    &_apple:before {
      background-image: url(./asset/appstore.png);
    }

    &_google:before {
      background-image: url(./asset/google-play.png);
    }
  }

  &__feedback {
    position: relative;
    left: -13px;
    width: 135px;
    height: 24px;
    padding-left: 13px;
    margin-top: 7px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    background-image: url(./asset/feedback-footer-bg.png);
  }

  &__bottom {
    display: flex;
    padding-top: 11px;
  }

  &__bottom-left {
    display: flex;
    flex-wrap: wrap;
    margin-right: 40px;
    font-size: 13px;
    line-height: 18px;
  }

  &__link-face {
    display: none;
  }

  @media (max-width: 1200px) {

    &__content-right {
      width: 60%;
    }

    &__col_large {
      font-size: 13px;
      line-height: inherit;

      > div {
        padding-bottom: 13px;
      }
    }

    &__feedback {
      margin-top: 2px;
    }

    &__store:before {
      width: 20px;
      height: 16px;
      margin-right: 5px;
      background-size: contain;
    }
  }
  @media (max-width: 1050px) {
    padding: 17px 10px 19px;

    &__content {
      flex-wrap: wrap;
    }

    &__content-right {
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 17px;
      border: none;
      border-bottom: 1px solid #f2f2f2;
    }

    &__col {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      padding-bottom: 9px;
      border-radius: 3px;

      &:first-child {
        display: none;
      }

      &:nth-child(2) {
        order: 1;
      }

      &:last-child {
        justify-content: space-around;
        padding: 0;
      }

      &_large {
        font-size: 17px;
        line-height: 18px;
      }

      > * {
        padding-right: 11px;

        &:last-child {
          padding-right: 0;
        }
      }

      &-phone {
        width: 100%;
        padding-top: 7px;
        padding-right: 0;
        font-size: 28px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
      }

      &-email {
        order: 1;
        padding: 0;
      }
    }

    &__link {
      font-size: 17px;
      font-weight: 500;
      line-height: 20px;
      color: #000000;
      text-align: center;
      border-bottom: none;
    }

    &__link-face {
      display: block;
      padding-top: 1px;
      font-size: 14px;
      font-weight: normal;
      line-height: 16px;
      color: #858585;
      text-align: center;
    }

    &__feedback {
      display: none;
    }

    &__bottom {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0;
      font-size: 13px;
      line-height: 18px;

      &-left {
        flex-grow: 1;
        margin-right: 0;
        font-size: 11px;
        line-height: 14px;
      }
    }

    &__menu-link {
      padding-bottom: 10px;
    }

    &__phone-link {
      border: none;
    }
  }
  @media (max-width: 1000px) {
    display: none;
  }
}
</style>
