<script setup lang="ts">
import hoverintent from 'hoverintent'
import { useAuthStore } from '~/store/modal/auth'
import { useUserStore } from '~/store/user'
import { useCartStore } from '~/store/cart'

const userPanelWrap = shallowRef<HTMLElement>()
const route = useRoute()

let $hoverintent: ReturnType<typeof hoverintent> | null
const isActive = ref(false)
const userStore = useUserStore()
const cartStore = useCartStore()
const userData = computed(() => userStore.data)
const unsubscribe = userStore.$onAction((mutation) => {
  /**
    *  Если мы только авторизовались/зарегистрировались, то вызываем библиотеку hoverintent
  */
  if (mutation.name === 'set')
    window?.requestAnimationFrame(() => (mutation.store?.data ? activateHoverIntent() : destroyHoverIntent()))
})

onMounted(() => {
  if (userData.value)
    activateHoverIntent()
})

onUnmounted(() => {
  unsubscribe?.()
  destroyHoverIntent()
})

const { enable: enableAuthModal } = useAuthStore()

function onMouseOver() {
  isActive.value = true
}

function onMouseOut() {
  isActive.value = false
}

function activateHoverIntent() {
  destroyHoverIntent()
  if (userPanelWrap.value)
    $hoverintent = hoverintent(userPanelWrap.value, onMouseOver, onMouseOut)
}

function destroyHoverIntent() {
  if ($hoverintent) {
    $hoverintent.remove()
    isActive.value = false
    $hoverintent = null
  }
}

async function onLogout() {
  await userStore.logout()
  cartStore.reset()
  if (route.path.includes('/user')) {
    navigateTo({
      path: '/',
    })
  }
}
</script>

<template>
  <div class="user-menu" :class="[{ 'user-menu_active': isActive }]">
    <template v-if="userData">
      <div class="user-menu__wrap">
        <div ref="userPanelWrap" class="user-menu__name-wrap">
          <span class="user-menu__name"> {{ userData.name || userData.phone }} </span>
          <div class="user-menu__hidden-block">
            <nuxt-link
              v-for="(el, index) in [
                { name: 'Список заказов', url: '/user/order/' },
                { name: 'Настройки', url: '/user/setting/' },
              ]"
              :key="index"
              :to="el.url"
              class="user-menu__hidden-el link link_wu"
            >
              {{ el.name }}
            </nuxt-link>
            <span class="user-menu__hidden-el link link_wu" @click="onLogout">Выход</span>
          </div>
        </div>
      </div>
      <nuxt-link to="/user/" class="user-menu__mobile-profile link link_wu">
        <ColorAvatar
          :img="userData.avatar"
          :name="userData.name"
          size="30"
          mobile-size="25"
        />
      </nuxt-link>
      <!-- <nuxt-link class="user-menu__profile user-menu__profile_mobile link link_wu" to="/user/">
          <img src="~@/assets/images/svg/profile.svg" alt="профиль" class="user-menu__profile-img" />
        </nuxt-link> -->
    </template>
    <template v-else>
      <div
        class="user-menu__profile link link_wu"
        data-e2e="login-button"
        @click="enableAuthModal()"
      >
        <span class="user-menu__profile-text mr-10 fz-16 lh-17 b">Вход</span>
        <img
          src="./asset/profile.svg"
          alt="профиль"
          class="user-menu__profile-img"
        >
      </div>
    </template>
  </div>
</template>

<style lang="postcss" scoped>
.user-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 115px;
  max-width: 163px;

  &_active {

    ^&__hidden-block {
      position: absolute;
      top: -10px;
      left: -13px;
      z-index: var(--z-below-blackout);
      display: flex;
      flex-direction: column;
      width: calc(100% + 30px);
      min-width: 133px;
      padding: 40px 13px 14px 13px;
      background-color: #fff;
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.04);
    }
  }

  &__profile {
    display: flex;
    align-items: center;

    &_mobile {
      display: none;
    }
  }

  &__login,
  &__registration {
    font-size: 13px;
    cursor: pointer;
  }

  &__login {
    margin-right: 10px;
  }

  &__name {
    position: relative;
    z-index: calc(var(--z-below-blackout) + 1);
    display: block;
    max-height: 17px;
    padding-right: 17px;
    overflow: hidden;
    font-size: 16px;
    font-weight: 500;
    line-height: 17px;
    color: #000;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    background-image: url(./asset/down-arrow.svg);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 10px 6px;
    transition: opacity, 0.2s;

    &-wrap {
      flex-grow: 1;
    }
  }

  &__wrap {
    display: flex;
    align-items: center;
    padding-right: 16px;
  }

  &__hidden-block {
    display: none;
  }

  &__hidden-el {
    margin-bottom: 13px;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__mobile-profile {
    display: none;
  }

  @media (max-width: 800px) {
    min-width: auto;

    &__profile-text,
    &__wrap {
      display: none;
    }

    &__mobile-profile {
      display: block;
    }
  }
}
</style>
